* {
  margin: 0;
  padding: 0;
 
}



.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #0ec2d6;
  color:rgb(194, 16, 16);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
  height: 100vh;
}

.card {
  height: 50vh;
  border-radius: 13px;
  box-shadow: 20px 40px 33px rgba(0,0,0,0.3);
  padding: 2rem;
  width: 35vw;
  background-color:  #6cc2ce;

}

.product-canvas {
  width: 35vw;
  height: 38vh;
  background:white;
  box-shadow:  10px 15px 15px 6px #3891b4;
  border-radius: 0px;
  margin-bottom:20px;
  
}

.colors {
display:flex;
flex-direction:row;
}

.colors div {padding:8px;}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* display: flex; */
  height: 100vh;
  margin: 0;
}

.controls {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
  /* display: flex; */
  flex-direction: column;
}

.controls div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
/* 
.product-canvas {
  width: 600px;
  height: 600px;
} */


.container {
  display: flex;
  width: 100%;
}

.model-container {
  width: 300%;
  height: 35.62%;
  padding-top: 100px;
  /* padding-left: 30px; */
  background-color: #f7f6f6;
  border: 2px solid black; /* Add this line to create a black border */
  margin-top: -5.5%;
}

.controls-container {
  width: 50%;
  padding-top:0px;
  box-sizing: border-box;
}

/* .controls {
  display: flex;
  flex-direction: column;
} */

.controls > div {
  margin-bottom: 20px;
}

.product-canvas {
  height: 100%;
}
